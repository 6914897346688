<script setup>
const props = defineProps({
  data: Object,
  location: Object
})
const { urlRegisterFunction } = useUtils();
let cardProgramsData = null;
let images = null;
if (props.data.sharedContent) {
  if (props.data.programs.data) {
  cardProgramsData = props.data.programs.data.map(program => {
    return {
      id: program.id,
      ...program.attributes, 
    }
  });
  } else {
    cardProgramsData = props.data.programs
  }
} else {
  cardProgramsData = props.data.cards;
  cardProgramsData.forEach((data) => {
    if (data.locationCTAs && data.locationCTAs.verticalGallery) {
      if (data.locationCTAs.vgRel.images && data.locationCTAs.vgRel.images.data) {
        // Convert the images object into an array
        const imagesArray = data.locationCTAs.vgRel.images.data.map((image) => {
          return {
            id: image.id,
            ...image.attributes,
          };
        });
        // Replace the images property with the array
        data.locationCTAs.vgRel.images = imagesArray;
      }
    }
  });
}
let cardsNumber = cardProgramsData.length
let colClass = 'col-md-12', colInnerClass = 'col-md-6'

if (cardsNumber == 3) {
  colClass = 'col-md-4'
  colInnerClass = 'col-md-12'
} else if (cardsNumber == 2) {
  colClass = 'col-md-6'
  colInnerClass = 'col-md-12'
} else if (cardsNumber == 1) {
  colClass = 'col-md-12'
  colInnerClass = 'col-md-6'
}
// Computed property to calculate column class based on index
const getColumnClass = (index) => {
  if (index % 3 === 0) {
    // 1st, 4th, 7th, etc. images take up full width
    return 'col-12 py-4 px-4';
  } else {
    // 2nd, 3rd, 5th, 6th, 8th, 9th, etc. images share the width
    return 'col-6 d-inline-block px-4';
  }
};

const showBadge = (badgeText, locationCTAs) => {
  if(badgeText) {
    if (locationCTAs !== null && locationCTAs.verticalGallery) {
      return false;
    }
    return true;
  }
  return false;
}

const goToAVC = (divisionId) => {
  if (divisionId > 0) {
    location.href = `#/reg-flow/avail-charts-lock?lid=${props.location.pulseId}&did=${divisionId}&rgnad=true`
    Pulse.app.refreshRoute();
  }
  
  // Scroll to element
  const offset = document.getElementById('www-spa-container').offsetTop;
  const deviceOffset = screen.width >= 992 ? 80 : 0;
  const scrollTopTo = offset - 130 - deviceOffset;  
  window.scrollTo({
    top: scrollTopTo,
    behavior: 'smooth',
  });
}
</script>

<template>
  <div class="block card-programs">
    <div class="container">
      <div class="row justify-content-center">
        <div :class="'col-12 ' + colClass" v-for="card in cardProgramsData">
          <div class="card-program-item">
            <div class="row">
              <div :class="'col-12 ' + colInnerClass + ' card-image-container'">
                <div class="position-relative">
                  <NuxtImg :src="card.image.url" class="card-image" :title="card.image.caption" :alt="card.image.alternativeText"></NuxtImg>
                  <div :class="'card-badge bg-' + card.badgeColor" v-html="card.badgeText" v-if="showBadge(card.badgeText, card.locationCTAs)"></div>
                  <div class="btn btn-secondary card-badge" data-bs-toggle="modal" :data-bs-target="'#gallery-' + card.id" 
                  v-if="card.locationCTAs && card.locationCTAs.verticalGallery">{{ card.locationCTAs.vgBtnText }}</div>
                </div>
                <template v-if="card.locationCTAs && card.locationCTAs.verticalGallery">
                  <UIModal :modalId="'gallery-' + card.id" modalClass="modal-fullscreen modal-gallery" 
                    :modalFooterBtnText="card.locationCTAs.vgRel.buttonText"
                    modalHeaderClass="d-none"
                    modalFooterBtnStyle="btn btn-primary">
                    <div class="container text-center">
                      <h2 class="text-start col-12">{{card.locationCTAs.vgRel.galleryTitle}}</h2>
                      <div v-for="(media, index) in (card.locationCTAs.vgRel.images)" :key="index" :class="getColumnClass(index)">
                        <NuxtImg :src="media.url" :title="media.caption" :alt="media.alternativeText" class="img-fluid"/>
                      </div>
                    </div>
                  </UIModal>
                </template>
              </div>
              <div :class="'col-12 ' + colInnerClass + ' card-information'">
                <div class="card-title" v-if="card.iconOptional">
                  <NuxtImg :src="card.iconOptional.url" class="card-image" :title="card.iconOptional.caption" :alt="card.iconOptional.alternativeText" />
                </div>
                <article class="card-content" v-html="card.description"></article>
                <div v-if="card.locationCTAs && card.locationCTAs.togglePrimaryCTA && location.status.typeId == 1" class="card-btn-container">
                  <a class="btn btn-primary mb-3 d-none d-md-block" @click="goToAVC(card.locationCTAs.divisionId)">{{ card.locationCTAs.primaryCTA ? card.locationCTAs.primaryCTA : 'View courses, prices & dates' }}</a>
                  <a class="btn btn-primary mb-3 d-block d-md-none" :href="urlRegisterFunction(card.locationCTAs.divisionId ? `?lid=${location.pulseId}&did=${card.locationCTAs.divisionId}` : `?lid=${location.pulseId}`, true)">{{ card.locationCTAs.primaryCTA ? card.locationCTAs.primaryCTA : 'View courses, prices & dates' }}</a>
                </div>
                <div v-if="card.locationCTAs && card.locationCTAs.toggleSecondaryCTA" class="card-btn-container">
                  <NuxtLink class="btn btn-outline-secondary" data-bs-toggle="modal" :data-bs-target="'#sample-schedule-' + card.id">{{ card.locationCTAs.secondaryCTA ? card.locationCTAs.secondaryCTA : 'View sample schedule' }}</NuxtLink>
                  <UIModal :modalId="'sample-schedule-' + card.id" modalClass="modal-sample-schedule">
                    <template v-slot:extra-title>
                      <div class="extra-title" v-if="card.iconOptional">
                        <NuxtImg :src="card.iconOptional.url" class="card-image" :title="card.iconOptional.caption" :alt="card.iconOptional.alternativeText" />
                        <h6 class="modal-title px-2">{{card.locationCTAs.modalTitle}}</h6>
                      </div>
                    </template>
                    <article v-html="card.locationCTAs.sampleScheduleContent"></article>
                  </UIModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .card-programs {
    .card-program-item {
      border-radius: 24px;
      border: solid 1px $color-light-gray;
      padding: 20px;
      margin-bottom: 10px;
      background: $color-secondary;
      color: $color-body-text;

      .card-image-container {
        // position: relative;

        .card-image {
          border-radius: 12px;
          max-width: 100%;
          margin: 0 auto;
          display: block;
          // position: relative;
        }
        .card-badge {
          padding: 2px 8px;
          border-radius: 10.5px;
          position: absolute;
          bottom: 0.625rem;
          right: 1.5rem;
          z-index: 1;
        }
      }

      .card-information {
        .card-title, .card-content {
          display: block;
          margin-top: 20px;
          padding: 0 20px;
        }
        .card-title {
          font-size: 1.25rem;
          font-weight: bold;
        }
        .card-content {
          margin-bottom: 40px;
          font-size: $font-size-base;

          a.btn {
            width: 100%;
          }
        }

        .modal-sample-schedule {
          .modal-header {
            .extra-title {
              font-size: 1.25rem;
              font-weight: bold;
            }
          }

          .modal-body {
            padding: 0;

            ul {
              list-style: none;
              margin: 0;
              padding: 0;

              li {
                border-top: 1px solid #dedede;
                display: flex;
                align-items: center;
                padding: 5px 25px;
                font-size: 1rem;
              }
            }

            li > * {
              flex: 1 1 auto;

              &:first-child {
                flex: 0 0 25%;
                padding-left: 0;
                font-weight: bold;
              }

              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
        .card-btn-container {
          a.btn {
            width: 100%;
          }
        }
      }
      .modal-gallery {
        img {
          border-radius: 24px;
        }
        .modal-footer {
          justify-content: center;
        }
      }
    }
  }
</style>